import $ from 'jquery';

/**
 * Download popup in databables
 */
export default function () {
    const modal = document.getElementById('downloadModal');
    if (!$(modal).length) return;

    modal.addEventListener('shown.bs.modal', function () {
        // Button that triggered the modal
        let button = event.relatedTarget
        // Extract info from data-bs-* attributes
        let id = button.getAttribute('data-bs-id')

        // Update the modal's content.
        $(".download-button").each(function () {
            let link = $(this).data('download-link');
            link = link.replace("[id]", id);
            $(this).attr("href", link);
        });
    })
}