/**
 * Form frontend validation
 * https://getbootstrap.com/docs/5.0/forms/validation/
 */
export default function (selectedForm, selectedEvent) {
    'use strict'
    if (selectedForm, selectedEvent) {
        return validate(selectedForm, selectedEvent);
    } else {
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        let forms = document.querySelectorAll('.needs-validation')

        // Loop over them and prevent submission
        Array.prototype.slice.call(forms)
            .forEach(function (form) {
                form.addEventListener('submit', function (event) {
                    validate(form, event)
                }, false)
            });
    }

    // Extract validate function
    function validate(form, event) {
        if (!form.checkValidity()) {
            event.preventDefault()
            event.stopPropagation()
        }
        form.classList.add('was-validated')
        return form.checkValidity();
    }
}