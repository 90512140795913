import 'select2'
import $ from "jquery";

let lang = $('html')[0].lang;
$.fn.select2.amd.define('select2/i18n/' + lang, [], require("select2/src/js/select2/i18n/" + lang));

export const defaultSelect2OptionsSingle = {
    theme: 'bootstrap-5',
    language: lang,
    closeOnSelect: true,
    scrollAfterSelect: false,
    width: '100%',
    escapeMarkup: function (markup) {
        return markup;
    },
    templateResult: formatState
}

export const defaultSelect2OptionsMulti = {
    theme: 'bootstrap-5',
    language: lang,
    closeOnSelect: false,
    scrollAfterSelect: false,
    width: '100%',
    templateResult: formatState
}

export function setAllSelects(parent = 'body', options = {}) {
    let selector = parent + ' select:not(.select2-hidden-accessible), .remote-select';

    $(selector).each(function () {

        let select2Options = {...defaultSelect2OptionsMulti, ...options};
        const amid = $(this).attr('data-automation-id') + '-select2';
        if (!this.hasAttribute('multiple')) {
            if ($(this).data('placeholder') && !$(this).hasClass("allow-clear-forbidden")) {
                $(this).attr('data-allow-clear', 'allow-clear');
            }
            select2Options = {...defaultSelect2OptionsSingle, ...options};
        }

        if (this.hasAttribute('data-source-url')) {
            let amidBase = '<span data-automation-id="' + $(this).attr('id') + '-';
            select2Options.minimumInputLength = 1;
            select2Options.ajax = {
                url: $(this).data('source-url'),
                dataType: 'json',
                delay: 500,
                processResults: function (data) {
                    // format with automation id
                    data.forEach(function (item) {
                        item.text = amidBase + item.id + '">' + item.text + '</span>';
                    });
                    return {
                        results: data
                    };
                }
            }
            // Replace input text type with select
            if ($(this).hasClass('remote-select')) {
                let input = $(this);
                let val = input.val();
                let classes = input.prop('class');
                let id = input.prop('id');
                let name = input.prop('name');
                let placeholder = input.data('placeholder');
                let select = $('<select>').addClass(classes).prop('id', id).prop('name', name);
                select.val(val);
                if (placeholder) {
                    // append empty option for the placeholder
                    select.append(new Option(placeholder, ''));
                }
                input.replaceWith(select);
                $(select).select2(select2Options);
            }
        }
        if (!$(this).hasClass('remote-select')) {
            $(this).select2(select2Options);
        }
        $(this).siblings('.select2-container').attr('data-automation-id', amid);

    });
}

export function setSingleSelect(el, opts = {}) {
    const amid = el.attr('data-automation-id') + '-select2';
    el.select2(opts);
    el.siblings('.select2-container').attr('data-automation-id', amid);
}

$(document).ready(function () {
    setAllSelects();
});

// Format result state (e.g. options)
function formatState(state) {
    if (!state.id || !state.element) {
        return state.text;
    }
    let selectElement = $(state.element).parent('select').attr('id');
    return $('<span data-automation-id="' + selectElement + '-' + state.element.value.toLowerCase() + '">' + state.text + '</span>');
}


$('.select-all').click(function () {
    $(this).siblings('select').select2('destroy').find('option').prop('selected', 'selected').end();
    setSingleSelect($(this).siblings('select'), defaultSelect2OptionsMulti);
})
$('.deselect-all').click(function () {
    $(this).siblings('select').select2('destroy').find('option').prop('selected', false).end();
    setSingleSelect($(this).siblings('select'), defaultSelect2OptionsMulti);
})

/**
 * Trigger pageload on change event
 */
$('select.triggerChange').change(function () {
    window.location.href = $(this).data('change-url') + '/' + $(this).val();
})