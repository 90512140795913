export default function () {
    const handleAlertFadeOut = (element) => {
        setTimeout(() => {
            if ($(element).is(":visible")) {
                $(element).fadeOut("slow");
            }
        }, 3000);
    };

    // Handle alerts present at initial load
    $(".alert.fade").each(function () {
        handleAlertFadeOut(this);
    });

    // Observer for dynamically created elements
    const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
            mutation.addedNodes.forEach(node => {
                if ($(node).hasClass('alert') && $(node).hasClass('fade')) {
                    handleAlertFadeOut(node);
                }
            });
        });
    });

    observer.observe(document.body, {
        childList: true,
        subtree: true
    });
}