import * as idb from './idbStore';
import $ from "jquery";
import autoStore from "./autoStore";

export default function () {
    // Only bother checking if we have inspection module active and aren't actually performing the inspection
    if (window.checkIdb && typeof (getQandAPath) === 'undefined' && typeof (window.inIspectionsModule) === 'undefined') {
        let req = idb.openDb();

        // Check cookie first
        let lockCookie = idb.getLockCookie();
        let destroy;
        if (!lockCookie) {
            // If we don't know what the have, we can't do anything with it so silently destroy
            destroy = true;
        } else if ((lockCookie.accountId === $('#js-accountlocation').data('account-id')) && (lockCookie.userId === $('#js-user').data('user-id'))) {
            // It's us! Don't auto-destroy
            destroy = false;
        } else {
            // Other physical person and/or account, don't intermix data but auto-destroy
            destroy = true;
        }
        checkData(req, destroy, lockCookie);
    }
}

function checkData(req, destroy, lockCookie) {
    const interval = setInterval(function () {
        if (req.readyState === "done") {
            if (req.result.objectStoreNames.length > 0) {
                let qaGetter = idb.checkInfo();
                qaGetter.then(function (records) {
                    if (records) {
                        if (destroy) {
                            idb.destroyDB();
                        } else {
                            let localTimestamp = new Date(lockCookie.timeStamp);
                            let serverTimestamp = 0;
                            $.get(window.checkInspectionTimestampUrl + '/' + lockCookie.inspectionId, function (data) {
                                serverTimestamp = data.timestamp;
                                if (localTimestamp >= serverTimestamp) {
                                    autoStore();
                                } else {
                                    let message = window.checkIdbMessage + localTimestamp.getFullYear() + '-' + (localTimestamp.getMonth() + 1) + '-' + localTimestamp.getDate() + ' ' + localTimestamp.getHours() + ':' + localTimestamp.getMinutes();
                                    if (confirm(message)) {
                                        autoStore();
                                    }
                                }
                            });
                            idb.destroyDB();
                        }
                    }
                });
            }
        }
        clearInterval(interval);
    }, 100);
}