import $ from 'jquery';

/**
 * Handle primary menu width
 */
export default function () {

    $( 'li.list-select' ).hover (
        function() {
            $(this).has( 'ul.submenu' ).parent().addClass('width-540');
        }, function() {
            $(this).has( 'ul.submenu' ).parent().removeClass('width-540');
        }
    );
}