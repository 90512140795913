/*
 * app.js is the style en javascript endpoint for the entire application
 * webpage or module specific javascript or css should get their own endpoint !
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';
// Import bootstrap 5
import 'bootstrap';
import frontendValidation from './components/frontendValidation';
import firstMenuResize from './components/firstMenuResize';
import download from './components/download';
import $ from "jquery";
import './components/select2'
import cleanupIdb from './components/inspections/cleanupIdb'
import './components/poppers'
import alerts from './components/alerts'
import './components/toggleSwitches';
import toggleSwitches from "./components/toggleSwitches";

$(document).ready(function () {
    frontendValidation();
    firstMenuResize();
    download();
    cleanupIdb();
    alerts();
    toggleSwitches();
})
;
